import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
// import Papa from "papaparse";
import { leaderboards } from "src/api/poker";
import Hero from "src/components/Hero/Hero";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import SearchablePaginatedTable from "src/components/Table/SearchablePaginatedTable";

const TournamentLeaderboard = () => {
  const { t, i18n } = useTranslation();
  // const [jsonData, setJsonData] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState(null);

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const csvData = reader.result;
  //     const parsedData = Papa.parse(csvData, { header: true }).data;
  //     setJsonData(parsedData);
  //   };

  //   reader.readAsText(file);
  // };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const data = await leaderboards("tlb");
        setLeaderboardData(data);
      } catch (error) {
        console.error("Failed to fetch leaderboard data:", error);
      }
    };

    fetchLeaderboard();
  }, []);

  return (
    <>
      <MainDiv>
        <Hero
          bgColor=""
          bgImage="poker/tlb/TLB-hero-image-2400x800px.jpg"
          buttonLink=""
          buttonText=""
          description={t("poker.tlb.dateRange")}
          descriptionBgColor=""
          image={`poker/tlb/logo-${i18n.language}-2400x2400px.png`}
          imageAlt=""
          // mainHeroText={t("poker.tlb.tournamentLeaderboard_h1")}
          title=""
        />
        <container-div>
          <SubTitleSection subTitle={t("poker.tlb.prizeAnnouncement")} />
          <ReactMarkdown>{t("poker.tlb.prizeDescription")}</ReactMarkdown>
          <ReactMarkdown>{t("poker.tlb.climbLeaderboard")}</ReactMarkdown>
          <SearchablePaginatedTable
            headers={leaderboardData?.headers || []}
            data={leaderboardData?.rows || []}
            searchKeys={["player_name"]}
            image={{
              src: "promotions/tlb_header_v2.png",
              alt: "tlb_header_v2",
            }}
          />
          <div className="tlb_rules">
            <ReactMarkdown>{t("poker.tlb.prizeDisclaimer1")}</ReactMarkdown>
            <ReactMarkdown>{t("poker.tlb.prizeDisclaimer2")}</ReactMarkdown>
          </div>
          <SubTitleSection subTitle={t("poker.tlb.scoringInfoTitle")} />
          <div className="tlb_rules">
            <ReactMarkdown>
              {t("poker.tlb.scoringInfoDescription")}
            </ReactMarkdown>
            <ReactMarkdown>{t("poker.tlb.contactInfo")}</ReactMarkdown>
          </div>
        </container-div>
      </MainDiv>
    </>
  );
};
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  background: var(--black);
  .main-hero-text h1 {
    font-size: clamp(1rem, 4vw + 1rem, 4rem);
    padding: clamp(1rem, 4vw + 1rem, 4rem) 1rem;
  }
  p {
    color: var(--white);
    margin: 0;
  }
  .tlb_rules p {
    color: var(--grey);
  }
  .hero_image_container {
    padding: 2rem;
  }
`;

export default TournamentLeaderboard;
