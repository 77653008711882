import React, { useState } from "react";
import styled from "styled-components";
import { IoSearch } from "react-icons/io5";
import Pagination from "react-bootstrap/Pagination";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { formatAmount } from "src/utils/formatters";

const SearchablePaginatedTable = ({
  image,
  headers = [],
  data = [],
  searchKeys = [],
  itemsPerPage = 25,
  renderRow, // optional custom row rendering function
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredData = data.filter((row) =>
    searchKeys.some((key) => row[key]?.toLowerCase().includes(searchQuery))
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const visiblePages = Array.from(
    { length: totalPages },
    (_, i) => i + 1
  ).slice(Math.max(currentPage - 2, 0), Math.min(currentPage + 1, totalPages));

  return (
    <TableDiv>
      <Row>
        {image && (
          <Col md={6}>
            <ResponsiveImage src={image.src} alt={image.alt} />
          </Col>
        )}
        <Col>
          <div>
            <input
              onChange={handleSearchChange}
              type="text"
              placeholder={t("poker.tlb.searchPlaceholder")}
            />
            <IoSearch />
          </div>
        </Col>
      </Row>
      <table>
        <thead>
          <tr>
            {headers?.map((el) => (
              <th key={el}>{t(`poker.tlb.${el}`)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) =>
            renderRow ? (
              renderRow(row, index)
            ) : (
              <tr key={row + index}>
                {headers.map((h, i) =>
                  h === "tlb_points" ? (
                    <td key={i}>
                      {formatAmount(row[h], i18n.language, false)}
                    </td>
                  ) : (
                    <td key={i}>{row[h]}</td>
                  )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>

      <div className="pagination-wrapper">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
            disabled={currentPage === 1}
          />
          {currentPage > 2 && <Pagination.Ellipsis />}
          {visiblePages.map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          {currentPage < totalPages - 1 && <Pagination.Ellipsis />}
          <Pagination.Next
            onClick={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </TableDiv>
  );
};

const TableDiv = styled.div`
  margin: 40px auto;
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  .row {
    .col {
      width: 100%;
      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      div {
        width: 100%;
        position: relative;
        input {
          --webkit-appearance: none;
          border: 1px solid #ccc;
          border-radius: 20px;
          color: #000;
          height: 36px;
          padding: 0 32px 0 12px;
          margin: 0;
          width: 100%;
        }
        & > svg {
          position: absolute;
          width: 20px;
          height: 100%;
          top: 0;
          right: 10px;
          text-align: center;
          cursor: pointer;
          color: var(--grey);
        }
      }
    }
  }
  table {
    border: 1px solid var(--lightblack);
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid var(--lightblack);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          & > strong {
            color: var(--red);
          }
          &.million {
            background-color: var(--red);
            & > strong {
              color: var(--white);
            }
          }
          &.seats {
            background-color: rgba(255, 255, 255, 0.1);
            & > strong {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  .pagination-wrapper {
    overflow-x: auto;
    margin-top: 20px;

    .pagination {
      justify-content: center;
      list-style: none;
      margin: 0 auto;
      padding: 0;

      .page-item {
        margin: 0 1px;

        &.active .page-link {
          background-color: var(--red);
          color: var(--white);
          font-weight: bold;
        }

        &.disabled .page-link {
          color: var(--grey);
        }

        .page-link {
          color: var(--bluelinkdark);
          background-color: var(--lightblack);
          border: 1px solid var(--black);
          padding: 7px 17px;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--mutedred);
            color: var(--white);
          }

          &:disabled {
            background-color: var(--grey);
            color: var(--lightgrey);
            cursor: not-allowed;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      grid-template-columns: 1fr;
      gap: 0;
      & > div:last-child {
        width: 100%;
        margin: 20px auto;
      }
    }
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 10px;
            padding: 5px;
            &.hide-col {
              display: none;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 10px;
            padding: 5px;
            font-weight: 300;
            &.hide-col {
              display: none;
            }
          }
        }
      }
    }
  }
`;
export default SearchablePaginatedTable;

// Usage example
{
  /* <SearchablePaginatedTable
  headers={leaderboardData?.headers || []}
  data={leaderboardData?.rows || []}
  searchKeys={["player_name"]}
  image={{
    src: "promotions/tlb_header_v2.png",
    alt: "tlb_header_v2",
  }}
/>; */
}
